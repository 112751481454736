<template>
  <div class="ReportErro">
    <v-btn
      class="fab-button-info ml-4"
      elevation="2"
      fab
      dark
      color="#0d8c1a"
      title="Relate um Erro"
      @click="dialog = true"
    >
      <v-icon>{{ mdiAlertOutline }}</v-icon>
    </v-btn>
    <div name="ReportErro">
      <v-row>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title class="card-title"> Relatar um Erro </v-card-title>
            <hr />
            <v-card-text>
              <v-container>
                <v-row class="text-left" id="">
                  <v-col cols="12">
                    <div class="label-margin">Email*</div>
                    <input
                      placeholder="nome@email.com"
                      type="email"
                      v-model="email"
                      outlined
                      required
                      class="custom-border custom-input"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="label-margin">Tipo de Problema*</div>
                    <input
                      placeholder="Escreva o tipo de problema"
                      v-model="tipoProblema"
                      outlined
                      required
                      class="custom-border custom-input"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="label-margin">Descrição do Problema*</div>
                    <v-textarea
                      counter
                      :rules="rules"
                      class="descricao-problema"
                      placeholder="Nos conte detalhadamente como o problema aconteceu"
                      v-model="descricaoProblema"
                      rows="6"
                      outlined
                      auto-grow
                      required
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="container-tipo">Anexo</div>
                    <v-file-input
                      ref="fileInput"
                      multiple
                      accept="image/*"
                      v-model="imagens"
                      label="Escolha um ou mais arquivos"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
              <small>*Indica um campo obrigatório</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button
                class="custom-btn custom-btn-cancel"
                @click="closeReport()"
              >
                CANCELAR
              </button>
              <button
                class="custom-btn custom-btn-success"
                @click="salvarErro()"
              >
                ENVIAR
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiAlertOutline } from "@mdi/js";
/* eslint-disable */
export default {
  name: "ReportErro",
  data: () => {
    return {
      rules: [(v) => v.length <= 500 || "Max 500 characters"],
      mdiAlertOutline,
      dialog: false,
      email: "",
      tipoProblema: "",
      descricaoProblema: "",
      imagens: [],
    };
  },
  props: {
    erro: "",
  },
  methods: {
    reportErro: function () {
      this.showReportErro = true;
    },
    salvarErro: function () {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("tipo", this.tipoProblema);
      formData.append("descricao", this.descricaoProblema);
      if (this.imagens.length > 0) {
        this.imagens.forEach((image, index) => {
          formData.append(`errosarquivos[${index}].Arquivo`, image);
          formData.append(`errosarquivos[${index}].TipoArquivo`, image.type);
        });
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      this.$http
        .post("/Report/Add", formData, { headers })
        .then((result) => {
          this.$swal("Sucesso", result.data, "success").then(() => {
            this.clearFields();
            this.$props.parent.resetload();
            this.dialog = false;
          });
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.$swal(
              "Erro",
              "Não foi possível processar, verifique os campos.",
              "error"
            );
          } else {
            this.$swal("Erro", error.response.data, "error");
          }
        });
    },
    clearFields: function () {
      this.email = "";
      this.tipoProblema = "";
      this.descricaoProblema = "";
      this.imagens = "";
    },
    closeReport: function () {
      this.clearFields();
      this.dialog = false;
    },
  },
  mounted() {},
  updated() {},
  computed: {},
};
</script>

<style scoped>
.container-tipo {
  margin-bottom: 5px;
}
.card-title {
  font-size: 24px !important;
  padding: 2rem 2rem 0rem 2rem !important;
  font-weight: 400 !important;
}
.custom-border {
  border-radius: 5px;
  border: 1px solid #9e9e9e;
}
.custom-input {
  height: 48px;
  width: 100%;
  padding: 1rem;
}
.label-margin {
  margin-bottom: 5px !important;
}
.custom-btn {
  margin-left: 1rem;
  padding: 5px;
  border-radius: 5px;
  font-size: medium;
}
.custom-btn:hover {
  background-color: rgba(231, 231, 231, 0.5);
}
.custom-btn-success {
  color: #0d8c1a !important;
}
.custom-btn-cancel {
  color: #f27474;
}
</style>

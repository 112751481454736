<template>
    <div id="searchContainer">
        <b-form @submit.stop.prevent="formsubmit">
            <b-form-group>
                <div id="input-group" class="input-group">
                    <b-form-input id="input-1"
                                  type="text"
                                  v-model="inputValue"
                                  class="form-control"
                                  value="showValue"
                                  :placeholder="placeholderInput"
                                  aria-label="Busca geral"
                                  aria-describedby="descriptionSearchBar"
                                  required />
                    <p id="descriptionSearchBar" style="opacity:0;position:absolute">{{inputValue ? inputValue : 'Insira o termo para a busca'}}</p>
                    <span class="input-group-append">
                        <v-btn id="searchButton"
                               aria-label="Pesquisar"
                               color="white"
                               type="submit"
                               elevation="0">
                            <v-icon id="iconButton"
                                    color="#3D3B3B"
                                    v-if="!loading"
                                    x-large>
                                {{iconMagnify}}
                            </v-icon>
                            <b-spinner v-else></b-spinner>
                        </v-btn>
                    </span>
                </div>
                <div class="d-flex justify-content-end">
                    <v-alert v-if="checkInput"
                             dense
                             type="error"
                             border="right"
                             class="mt-3 ml-"
                             id="alertSearch">
                        {{checkInput
                                }}
                                </v-alert>
</div>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
    import { mdiMagnify } from '@mdi/js';

    export default {
        name: 'SearchBar',
        data() {
            return {
                iconMagnify: mdiMagnify,
                inputValue: "",
                showValue: "",
                loading: false,
                error: null,
                placeholderInput: 'Insira o termo para a busca',
            }
        },
        props: {
          termo: String
        },
        methods: {
          async formsubmit() {
                await this.$emit('submitsearch', this.inputValue);
                if (this.inputValue != this.$route.params.q && !this.$route.name.includes('busca')) {
                    if (!this.checkInput && this.inputValue) {
                        this.$router.push({ name: 'Busca', params: { q: this.inputValue } });
                    }
                } else {
                    this.$emit('refresh')
                }
            },
        },
        computed: {
            checkInput: function () {
                return (this.inputValue.includes('/') ? "Caractere não permitido: /" : null);
            }
        },
        watch: {
            $route(to) {
                this.inputValue = to.params.q ? to.params.q : "";
            },
            termo(value) {
              if (value) {
                if (typeof value === Array) value = value.join('');
                this.showValue = value;
                this.placeholderInput = value;
                this.inputValue = value;
              } else this.placeholderInput = 'Insira o termo para a busca';
            }
        },
        mounted() {
            this.inputValue = this.$route.params.q ? this.$route.params.q : "";
        }
    }
</script>

<style>
    @import './SearchBar.css';
</style>

export function prepareTitle(descricao, numero) {
    let num = numero.replace( /^\D+/g, '');
    let rest = numero.replace(/\d+|\./g, '');
    num = parseInt(num.split(".").join("")).toLocaleString().split(',').join(".") + rest;
    return `${descricao} n° ${numero ? num : ""}`;
}

export function prepareDate(dataPublicacao) {
    let date = Date.parse(dataPublicacao);
    let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    let formatted = new Intl.DateTimeFormat('pt-BR', dateOptions).format(date);

    return `${formatted}`;
}

export function preparePath(name, tipoato, numero, ato) {
    return {
        name,
        params: {
            tipoato,
            numero,
            ato,
        }

    };
}
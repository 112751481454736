<template>
  <div
    data-app
    class="d-flex flex-nowrap flex-column flex-md-row"
    id="dFlexItemDisplay"
    v-if="atoContent"
  >
    <div id="ItemDisplayContainer" class="d-flex flex-column flex-grow-1">
      <div id="TopbarContainer" class="d-flex flex-row align-items-center pl-4">
        <v-img
          :src="require(`../../../assets/imgs/logodecreto.png`)"
          alt="Logo do Governo do Estado"
          contain
          max-height="46"
          max-width="37"
        />

        <div class="d-flex flex-column flex-md-row align-items-baseline py-2">
          <h2 id="h2Title" class="text-left ml-4">{{ prepareTitle }}</h2>
          <h3 id="h3Data" class="text-left ml-md-3 mx-4">{{ prepareDate }}</h3>
        </div>
      </div>

      <v-container class="px-5 py-4">
        <v-row class="mt-2">
          <v-col cols="6">
            <div class="px-5 text-left">
              <label class="field-label">Situação</label>
              <div class="field-content">
                {{ prepareSituacao(this.ato.situacao.id) }}
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="px-5 field-label text-left" outlined tile>
              <label for="" class="field-label">
                Publicação no diário Oficial
              </label>
              <div class="field-content">{{ preparePubDate }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="px-5 text-left">
              <label class="field-label">Ementa</label>
              <div class="field-content">{{ ato.ementa }}</div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="px-5 pt-5 field-label text-left" outlined tile>
              <label class="field-label">Iniciativa</label>
              <div class="field-content">
                {{
                  ato.iniciativa.descricao != null
                    ? ato.iniciativa.descricao
                    : "Não informado"
                }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="px-5 text-left">
              <v-row>
                <label class="field-label px-4">Arquivos</label>
              </v-row>
              <v-row class="mt-0">
                <v-col class="field-content" v-if="pdfsrc">
                  <a
                    :href="pdfsrc != null ? pdfsrc : '/notfound'"
                    target="_blank"
                    class="my-auto ml-2"
                  >
                    PDF Original
                    <v-icon>{{ newtab }}</v-icon>
                  </a>
                </v-col>
                <v-col class="field-content" v-if="pdfsrccons">
                  <a
                    :href="pdfsrccons != null ? pdfsrccons : '/notfound'"
                    target="_blank"
                    class="my-auto ml-2"
                  >
                    PDF Atualizado
                    <v-icon>{{ newtab }}</v-icon>
                  </a>
                </v-col>
                <v-col class="field-content" v-if="renderhtml">
                  <a
                    :href="renderhtml != null ? renderhtml : '/notfound'"
                    target="_blank"
                    class="my-auto ml-2"
                  >
                    HTML Original
                    <v-icon>{{ newtab }}</v-icon>
                  </a>
                </v-col>
                <v-col class="field-content" v-if="renderhtmlcons">
                  <a
                    :href="
                      renderhtmlcons != null ? renderhtmlcons : '/notfound'
                    "
                    target="_blank"
                    class="my-auto ml-2"
                  >
                    HTML Atualizado
                    <v-icon>{{ newtab }}</v-icon>
                  </a>
                </v-col>
              </v-row>
              <v-row v-if="!pdfsrccons && !renderhtmlcons">
                <v-col class="pt-0 helper-text">
                  Ato normativo sem atualizações ou não atualizado pela equipe
                  do LegisOn.
                </v-col>
              </v-row>  
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  prepareTitle as utilPrepareTitle,
  prepareDate as utilPrepareDate,
} from "@/utils/prepareTextAto.js";

import { mdiOpenInNew } from "@mdi/js";
import moment from "moment";

export default {
  name: "ItemDisplay",
  data: () => {
    return {
      newtab: mdiOpenInNew,
      hist: false,
      showing: "html",
      first: true,
      pdfsrc: null,
      pdfsrccons: null,
      renderhtml: null,
      renderhtmlcons: null,
    };
  },
  props: {
    ato: Object,
    atoContent: Object,
  },
  computed: {
    prepareAtoContent: function () {
      if (this.atoContent) {
        if (this.atoContent.conteudo) return this.atoContent.conteudo.ato;
        else if (this.atoContent.temas) return this.atoContent.temas.ato;
        else return null;
      } else return null;
    },
    prepareTitle: function () {
      return this.prepareAtoContent
        ? utilPrepareTitle(
            this.prepareAtoContent.tipoAto.descricao,
            this.prepareAtoContent.numero
          )
        : "Ocorreu um problema...";
    },
    prepareDate: function () {
      return this.prepareAtoContent
        ? `de ${utilPrepareDate(this.prepareAtoContent.dataAto)}`
        : "";
    },
    preparePubDate: function () {
      return new moment(this.ato.dataPublicacao).format("DD / MM / YYYY");
    },
    downloadOtptionEnabled: function () {
      return this.pdfsrc ? !this.hasHtml && this.pdfsrc.length > 0 : false;
    },
    downloadConsOtptionEnabled: function () {
      return this.pdfsrccons
        ? !this.hasHtmlCons && this.pdfsrccons.length > 0
        : false;
    },
    hasHtml: function () {
      return this.hasConteudo && this.atoContent.conteudo.texto;
    },
    hasHtmlCons: function () {
      return this.hasConsolidado && this.atoContent.consolidado.texto;
    },
    hasConteudo: function () {
      return (
        this.atoContent &&
        this.atoContent.conteudo &&
        (this.atoContent.conteudo.texto || this.atoContent.conteudo.caminhoPDF)
      );
    },
    hasHistory: function () {
      return this.hist;
    },
    hasConsolidado: function () {
      return (
        this.atoContent &&
        this.atoContent.consolidado &&
        (this.atoContent.consolidado.texto ||
          this.atoContent.consolidado.caminhoPDF)
      );
    },
    preparePath() {
      return `/html/${this.$route.params.tipoato}/${this.ato.id}?consolidado=0`; /*{
                name: "Visualização HTML",
                params: {
                  tipoato: this.$route.params.tipoato,
                  numero: this.$route.params.numero,
                  atoContent: this.atoContent.conteudo
                },
              };*/
    },
    preparePathCons() {
      return `/html/${this.$route.params.tipoato}/${this.ato.id}?consolidado=1`; /*{
                name: "Visualização HTML",
                params: {
                  tipoato: this.$route.params.tipoato,
                  numero: this.$route.params.numero,
                  atoContent: this.atoContent.consolidado
                },
              };*/
    },
    pdfPath() {
      return this.atoContent && this.atoContent.conteudo
        ? `${this.$route.path}/${this.atoContent.conteudo.caminhoPDF}`
        : "";
    },
  },
  methods: {
    history: function () {
      if (this.ato.constituicao) return false;
      this.$http
        .get("/Public/CheckHistorico?idato=" + this.ato.id)
        .then((result) => {
          this.hist = result.data.exists;
        })
        .catch(() => {
          return false;
        });
    },
    changeShow: function (to) {
      if (to === "pdf") this.showing = "pdf";
      if (to === "pdfc") this.showing = "pdfc";
      if (to === "html") this.showing = "html";
      if (to === "htmlc") this.showing = "htmlc";
    },
    getSourceHtml: function () {
      if (this.hasConteudo)
        if (this.atoContent.conteudo && this.atoContent.conteudo.texto) {
          this.renderhtml = this.atoContent.conteudo.texto
            .split("\n")
            .join("<br>");
        }
    },
    getSourcePDF: async function () {
      if (this.hasConteudo) {
        const id = this.atoContent.conteudo.idAto;
        this.pdfsrc = `${this.$apiURL}/uploads/atos/${id}/${this.atoContent.conteudo.caminhoPDF}`;
      }
    },
    getSourceHtmlCons: function () {
      if (this.hasConsolidado)
        if (this.atoContent.consolidado && this.atoContent.consolidado.texto) {
          this.renderhtmlcons = this.atoContent.consolidado.texto
            .split("\n")
            .join("<br>");
        }
    },
    getSourcePDFCons: async function () {
      if (this.hasConsolidado) {
        const id = this.atoContent.consolidado.idAto;
        this.pdfsrccons = `${this.$apiURL}/uploads/atos/${id}/${this.atoContent.consolidado.caminhoPDF}`;
      }
    },
    prepareSituacao(situacaoId) {
      console.log(situacaoId);
      return situacaoId == 0 ? "Não informado" : this.ato.situacao.descricao;
    },
  },
  watch: {
    async atoContent() {
      this.getSourceHtml();
      this.getSourcePDF();
      this.getSourceHtmlCons();
      this.getSourcePDFCons();
      if (this.first) {
        if (this.hasConsolidado) {
          if (!this.hasHtmlCons) this.showing = "pdfc";
          else this.showing = "htmlc";
        } else if (this.hasConteudo) {
          if (!this.hasHtml) this.showing = "pdf";
          else this.showing = "html";
        } else this.showing = "err";

        this.first = false;
      }
    },
    ato() {
      this.history();
    },
  },
  async mounted() {
    this.getSourceHtmlCons();
    this.getSourcePDFCons();
    this.getSourceHtml();
    this.getSourcePDF();

    if (this.hasConsolidado) {
      if (!this.hasHtmlCons) this.showing = "pdfc";
      else this.showing = "htmlc";
    } else if (this.hasConteudo) {
      if (!this.hasHtml) this.showing = "pdf";
      else this.showing = "html";
    } else this.showing = "err";
  },
};
</script>

<style>
.field-label {
  font-size: 3rem;
}
.field-content {
  font-size: 2rem;
}
.helper-text {
  margin-left: 5px;
  font-size: 1.5rem;
}
@import "./ItemDisplay.css";
</style>

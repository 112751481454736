<template>
  <footer
    :class="[['sm', 'xs'].includes(size) ? 'd-md-flex justify-content-between mb-0 pb-0' : 'd-md-flex justify-content-between' ]"
  >
    <div v-if="['sm', 'xs'].includes(size)" class="text-center">
      <center>
        <v-img
          :src="require('@/assets/imgs/logo-GovEstado-SE.png')"
          alt="Sergipe, Governo do Estado"
          max-width="180px"
        />
      </center>
    </div>
    <div v-else class="content-footer-left">
      <v-img
        :src="require('@/assets/imgs/logo-GovEstado-SE.png')"
        alt="Sergipe, Governo do Estado"
        max-width="180px"
      />
    </div>

    <div
      v-if="['xs', 'sm'].includes(size)"
      class="my-auto content-sm-footer-right d-md-flex"
    >
      <div class="my-4 text-width">
        Desenvolvido pela CODIN da Procuradoria-Geral do Estado de Sergipe em
        conjunto com a Secretaria Especial de Governo.
      </div>

      <v-row>
        <v-col cols="4">
          <ReportErro :erro="erroAtual" />
        </v-col>

        <v-col cols="4">
          <SolicitarAto />
        </v-col>

        <v-col cols="4">
          <v-btn
            class="fab-button-info ml-4"
            elevation="2"
            fab
            dark
            color="#0d8c1a"
            :loading="loading"
            title="Informações sobre o LegisOn"
            @click="$router.push('/sobre')"
          >
            <v-icon>{{ mdiInformationOutline }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else class="content-footer-right my-auto d-md-flex">
      <div class="my-auto">
        Desenvolvido pela CODIN da Procuradoria-Geral do Estado de Sergipe em
        conjunto com a Secretaria Especial de Governo
      </div>

      <ReportErro :erro="erroAtual" />

      <SolicitarAto />

      <v-btn
        class="fab-button-info ml-4"
        elevation="2"
        fab
        dark
        color="#0d8c1a"
        :loading="loading"
        title="Informações sobre o LegisOn"
        @click="$router.push('/sobre')"
      >
        <v-icon>{{ mdiInformationOutline }}</v-icon>
      </v-btn>
    </div>
  </footer>
</template>

<script>
import { mdiInformationOutline, mdiAlertOutline } from "@mdi/js";
import ReportErro from "../../views/Display/components/ReportErro.vue";
import SolicitarAto from "../../views/Display/components/SolicitarAto.vue";
export default {
  name: "Footer",
  data: () => {
    return {
      loading: false,
      mdiInformationOutline,
      mdiAlertOutline,
      erroAtual: "",
    };
  },
  components: {
    ReportErro,
    SolicitarAto,
  },
  computed: {
    size: function () {
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    reportErro: function (erro) {
      this.erroAtual = erro;
      this.showReportErro = true;
    },
  },
};
</script>

<style>
.text-width {
  max-width: 300px;
}
@import "./Footer.css";
</style>

<template>
  <div>
    <v-btn
      class="fab-button-info ml-4"
      elevation="2"
      fab
      dark
      color="#0d8c1a"
      title="Solicitar atualização de ato"
      @click="dialogState = !dialogState"
    >
      <v-icon>{{ mdiFileDocumentEdit }}</v-icon>
    </v-btn>

    <template>
      <v-row justify="center" data-app>
        <v-dialog v-model="dialogState" max-width="750px">
          <v-card>
            <v-card-title class="card-title">
              Solicitar atualização
            </v-card-title>
            <hr />
            <v-card-text>
              <v-container>
                <v-row class="text-left my-3">
                  <v-col class="pb-0" cols="12" sm="6" md="7">
                    <div class="label-margin">Número do ato</div>
                    <input
                      v-model="numeroDoAto"
                      placeholder="Ex: 22445"
                      type="number"
                      class="custom-border custom-input"
                      required
                    />
                  </v-col>
                  <v-col class="pb-0" cols="12" sm="6" md="5">
                    <div class="label-margin">Tipo do ato</div>
                    <select
                      v-model="tipoDoAto"
                      class="custom-select custom-border"
                      required
                    >
                      <option value="" disabled>Selecione um tipo</option>
                      <option
                        v-for="(tipo, index) in tiposDeAtos"
                        :key="index"
                        :value="tipo"
                      >
                        {{ tipo }}
                      </option>
                    </select>
                  </v-col>
                </v-row>
                <v-row class="text-left my-3">
                  <v-col class="pb-0" cols="12" md="7">
                    <div class="label-margin">Email</div>
                    <input
                      v-model="email"
                      type="email"
                      placeholder="nome@email.com"
                      class="custom-border custom-input"
                      required
                    />
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4">
                    <div class="label-margin">Data da solicitção</div>
                    <input
                      v-model="dataSolicitacao"
                      class="custom-border custom-input"
                      disabled
                      required
                    />
                  </v-col>
                </v-row>
                <v-row class="text-left">
                  <v-col cols="12" md="12" sm="6">
                    <div class="label-margin">Motivação</div>
                    <v-textarea
                      counter
                      :rules="rules"
                      v-model="motivo"
                      rows="10"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button
                class="custom-btn custom-btn-cancel"
                @click="dialogState = !dialogState"
              >
                CANCELAR
              </button>
              <button
                class="custom-btn custom-btn-success"
                @click="solicitarAtualizacao"
              >
                SOLICITAR
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>
<script>
import { mdiFileDocumentEdit } from "@mdi/js";
import moment from "moment";

export default {
  name: "SolicitarAto",
  data: () => {
    return {
      rules: [(v) => v.length <= 500 || "Max 500 characters"],
      dialogState: false,
      mdiFileDocumentEdit,
      tiposDeAtos: [
        "Constituição Estadual",
        "Decreto Numerado",
        "Emenda",
        "Lei Complementar",
        "Lei Ordinária",
      ],
      email: "",
      numeroDoAto: "",
      tipoDoAto: "",
      motivo: "",
    };
  },
  computed: {
    dataSolicitacao: function () {
      return moment(new Date()).format("DD/MM/yyyy");
    },
  },
  methods: {
    limparCampos: function () {
      this.numeroDoAto = "";
      this.email = "";
      this.tipoDoAto = "";
      this.motivo = "";
    },
    tratarNumero: function () {
      let numeroTratado = this.numeroDoAto
        ? Number(this.numeroDoAto.replace(/\D/g, ""))
        : "";
      return numeroTratado;
    },
    traduzirMsgErro: function (value) {
      if (value === "One or more validation errors occurred.")
        return "Um ou mais erros de validação ocorreram. Verifique se os campos estão corretos";
    },
    solicitarAtualizacao: function () {
      let atoSolicitado = {
        email: this.email,
        numeroAto: this.tratarNumero(),
        tipoAto: this.tipoDoAto,
        motivo: this.motivo,
      };
      console.log(atoSolicitado.motivo.length);
      if (atoSolicitado.motivo.length > 500) {
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Número de caracteres excedido! Escreva uma motivação com menos de 500",
          confirmButtonColor: "#f27474 ",
          heightAuto: false,
        });
      } else {
        this.$http
          .post("/SolicitacaoAto/Add", atoSolicitado, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Sucesso",
              text: `Solicitação de atualização do Ato - ${atoSolicitado.numeroAto} feita!`,
              confirmButtonColor: "#0d8c1a ",
              heightAuto: false,
            });
            this.dialogState = false;
            this.limparCampos();
          })
          .catch((err) => {
            this.dialogState = false;
            this.limparCampos();
            this.$swal({
              icon: "error",
              title: "Erro",
              text: this.traduzirMsgErro(err.response.data.title),
              confirmButtonColor: "#f27474 ",
              heightAuto: false,
            });
          });
      }
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-radius: 5px;
  border: 1px solid #9e9e9e;
}
.custom-input {
  height: 48px;
  width: 100%;
  padding: 1rem;
}
.custom-select {
  width: 200px;
  height: 48px;
  padding: 1rem;
  font-size: small !important;
}

.custom-btn {
  margin-left: 1rem;
  padding: 5px;
  border-radius: 5px;
  font-size: medium;
}
.custom-btn:hover {
  background-color: rgba(231, 231, 231, 0.5);
}
.custom-btn-success {
  color: #0d8c1a;
}
.custom-btn-cancel {
  color: #f27474;
}
.v-text-field.v-text-field--enclosed[data-v-43108f50] {
  margin: 0px !important;
  padding: 0px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.v-text-field.v-text-field--enclosed {
  margin: 5px !important;
  padding: 5px !important;
}
.v-input__slot {
  min-height: 52px !important;
}
.card-title {
  font-size: 24px !important;
  padding: 2rem 2rem 0rem 2rem !important;
  font-weight: 400 !important;
}
.label-margin {
  margin-bottom: 5px !important;
}
.v-list-item__title {
  font-size: 16px !important;
}
</style>

<template>
    <v-dialog max-width="320"
              v-model="errorDialog"
              persistent>
            <v-card rounded="lg">
                <v-card class="pa-4">
                    <v-card-title id="titleDialogError" class="text-left pa-0">
                        {{title}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text id="messageDialogError" class="text-left">
                        {{message}}
                    </v-card-text>

                    <v-card-actions>
                        <v-btn block
                               v-if="button"
                               id="buttonDialogError"
                               color="#444444"
                               class="mt-4 text-light"
                               to="/">
                            Voltar ao início
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogError',
        props: {
            errorDialog: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: "Não foi possível carregar esta página",
            },
            message: {
                type: String,
                default: "Tente novamente em alguns minutos...",
            },
            button: {
                type: Boolean,
                default: true,
            }
        },
        methods: {},          
    }
</script>

<style>
    @import './DialogError.css';
</style>

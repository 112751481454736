<template>
    <v-overlay :value="pLoading" opacity="0.9">

        <v-img :lazy-src="require(`@/assets/imgs/LoadingLegison.svg`)"
               :src="require(`@/assets/imgs/LoadingLegison.svg`)"
               alt="Logo LegisOn"
               contain
               absolute
               max-width="220px" />
        <b-spinner id="loadingSpinner"></b-spinner>
        <p class="mt-2" id="loadingText">Carregando...</p>
    </v-overlay>
</template>

<script>
    export default {
        name: 'LogoImage',
        props: {
            pLoading: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>

<style>
    @import './Loading.css';
</style>

<template>
  <div class="Display">
    <Loading :pLoading="loading" />

    <DialogError :errorDialog="errored"
      title="Parece que estamos com alguns problemas"
      message="Não foi possível carregar esta página agora. Tente novamente mais tarde..." />

    <NavComponent></NavComponent>
    <v-container>
      <div class="d-flex flex-column">

        <div class="logoSearch d-flex align-items-center justify-content-center flex-wrap">
          <router-link to="/" class="pt-2">
            <LogoImage pWidthLogo="14vw"
              pMaxWidthLogo="204"
              :subtEnabled="false" />
          </router-link>

          <v-flex grow class="inputAdvancedSearch">
            <SearchBar></SearchBar>
          </v-flex>

        </div>
        <div id="main">
          <ItemDisplay :ato="ato"
                       :atoContent="atoContent" />
        </div>
      </div>  
    </v-container>
    <Footer />
  </div>
</template>

<script>

  import NavComponent from '@/shared/components/NavComponent.vue';
  import SearchBar from '@/shared/components/SearchBar.vue';
  import LogoImage from '@/shared/components/LogoImage.vue';
  import ItemDisplay from './components/ItemDisplay.vue';
  import Loading from '@/shared/components/Loading.vue';
  import DialogError from '@/shared/components/DialogError.vue';
  import Footer from '@/shared/components/Footer.vue';

  export default {
    name: 'Display',
    title: 'Visualização - LegisOn',
    components: {
      NavComponent,
      SearchBar,
      ItemDisplay,
      LogoImage,
      Loading,
      DialogError,
      Footer
    },
    data: () => {
      return ({
        idAto: null,
        ato: null,
        atoContent: null,
        errored: false,
        errorMessage: false,
        loading: true,
      })
    },
    beforeRouteLeave(to, from, next) {
      localStorage.setItem('prevPage', from.path);
      next();
    },
    methods: {
      getAto: async function () {
        if (!this.idAto) {
          await this.$http.post("/Public/Consulta", this.getPostBody)
            .then(response => {
              var atos = response.data.result.map(x => {
                if (x.ato) return x.ato;
                else return x;
              });
              this.ato = atos.find((ato) => ato.numero == this.$route.params.numero);
              this.idAto = this.ato.id;
              this.getAto();
            })
            .catch(error => {
              this.errored = true
              this.errorMessage = error
            });
        }
        if (this.idAto !== null) {
          await this.$http.get(`/Public/GetConteudoAto?atosIds=${this.idAto}`)
            .then(async response => {
              this.loading = false;
              let content = response.data?.content;
              let files = response.data?.files;
              let temas = response.data?.temas;
              let orgaos = response.data?.orgaos;
              let conteudo = { conteudohtml: null, texto: null, caminhoPDF: null, ato: null, idAto: null };
              let consolidado = { conteudohtml: null, texto: null, caminhoPDF: null, ato: null, idAto: null };
              if (content?.length) {
                let cons = content.find(x => x.consolidado && x.disp);
                let orig = content.find(x => !x.consolidado && x.disp);
                if (cons) {
                  consolidado.conteudohtml = cons.conteudo ? cons.conteudo : null;
                  consolidado.texto = cons.textoFormatado ? cons.textoFormatado : null;
                }
                if (orig) {
                  conteudo.conteudohtml = orig.conteudo ? orig.conteudo : null;
                  conteudo.texto = orig.textoFormatado ? orig.textoFormatado : null;
                }
              }
              if (files?.length) {
                let orig = files.find(x => !x.consolidado && x.idAto === this.idAto);
                let cons = files.find(x => x.consolidado && x.idAto === this.idAto);
                if (orig) {
                  conteudo.caminhoPDF = orig.caminhoPDF;
                }
                conteudo.ato = orig?.ato || cons?.ato || this.ato;
                conteudo.idAto = orig?.idAto || cons?.idAto || this.idAto;
                if (cons) {
                  consolidado.caminhoPDF = cons.caminhoPDF;
                }
                consolidado.ato = cons?.ato || orig?.ato || this.ato; 
                consolidado.idAto = cons?.idAto || orig?.idAto || this.idAto;
              }
              var filtered = {
                conteudo: conteudo,
                consolidado: consolidado,
                temas: temas,
                orgaos: orgaos
              }
              this.atoContent = filtered;
            })
            .catch(error => {
              this.loading = false;
              this.errored = true
              this.errorMessage = error
            });
        }
      },
    },
    computed: {
      getPostBody: function () {
        let numeroAto = this.$route.params.numero;
        let idTipoAto = 0;
        if (this.$enumTipoAto)
          idTipoAto = Object.keys(this.$enumTipoAto) // Com o nome da rota se obtem o id do ato - ex: lei complementar
            .find(key => this.$enumTipoAto[key] === this.$route.params.tipoato);

        return {
          "Numero": numeroAto,
          "PalavrasChave": "",
          "Ementa": "",
          "IdTema": 0,
          "IdIniciativa": 0,
          "IdOrgao": 0,
          "IdTipo": idTipoAto,
          "IdUsuarioCriador": 0,
          "Order": 'desc',
          "Page": 1
        }
      }
    },
    mounted: async function () {
      if (!this.$enumTipoAto) {
        await this.$http.get("/TipoAto/Get")
          .then(response => {
            this.$enumTipoAto = response.data.reduce(function (result, item) {
              let key = item[Object.keys(item)[0]];
              let value = item[Object.keys(item)[1]].replace(/\s/g, "-")                // remove os espaços
                .toLowerCase()                      // converte em minusculo
                .normalize("NFD")                   // disassocia acentos de letras
                .replace(/[\u0300-\u036f]/g, "");   // remove os acentos

              result[key] = value;
              return result;
            }, {});
          })
          .catch(error => {
            throw error;
          });
      }
      if (this.$route.params.ato) {
        this.ato = this.$route.params.ato
        this.idAto = this.$route.params.ato.id
      }
      this.getAto();
    }
  }
</script>

<style>
  @import './Display.css';
</style>
